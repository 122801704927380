export const minutesSpentInADay = (spentMilliseconds: number, totalMilliseconds: number) => {
    let MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
    let totalDays = totalMilliseconds / MILLISECONDS_IN_A_DAY
    let minutesSpentInADay = spentMilliseconds / (1000 * 60 * totalDays);
    return minutesSpentInADay;
}

// Calculating rank based on the number of minutes that are spent on average in a day, for the activity
export const calculateRank = (spentMilliseconds: number, totalMilliseconds: number) => {
    let minutes = minutesSpentInADay(spentMilliseconds, totalMilliseconds);
    let rank = "";

    let remainder = minutes % 100;
    let position;
    if (remainder <= 33) {
        position = "Low ";
    } else if (remainder <= 66) {
        position = "Mid ";
    } else {
        position = "High ";
    }

    if (minutes < 200) {
        rank = "Untouchable"
    } else if (minutes < 300) {
        rank = position + "Pleb"
    } else if (minutes < 400) {
        rank = position + "Novice"
    } else if (minutes < 500) {
        rank = position + "Intermediate"
    } else if (minutes < 600) {
        rank = position + "Advanced"
    } else if (minutes < 700) {
        rank = position + "Elite"
    } else if (minutes < 800) {
        rank = position + "Rare Elite"
    } else if (minutes < 900) {
        rank = position + "Legendary"
    } else if (minutes < 1000) {
        rank = position + "Saint"
    } else {
        rank = "Planetary"
    }

    
    return rank;
}