import { DatePicker } from "@mui/x-date-pickers";
import { TimeRangePickerProps } from "./_props";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

export const MonthPicker = (props: TimeRangePickerProps) => {
    let { setStartDateTime, setEndDateTime } = props;

    const [date, setDate] = useState<Dayjs | null>(dayjs().startOf('month'));

    useEffect(() => {
        if (date) {
            let theDate = date.toDate();
            let year = theDate.getFullYear();
            let month = theDate.getMonth();

            let startDate = new Date(year, month);
            let endDate = new Date(year, month + 1);
            setStartDateTime(startDate);
            setEndDateTime(endDate);
        }
    }, [date])
    
    return (
    <>
        <DatePicker
            label="Month"
            value={date} 
            openTo="month"
            views={['month', 'year']}
            onChange={(date) => { setDate(date) }}
            />
    </>);
}