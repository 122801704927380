export const sumEventTimeInMilliseconds = (eventList: any) => {
    let totalMilliseconds = 0;

    // eslint-disable-next-line array-callback-return
    eventList.map((event: any) => {
        if (event.start) { /* To remove events that have been cancelled. */
            let eventMilliseconds = Math.abs(new Date(event.start.dateTime).getTime() - new Date(event.end.dateTime).getTime());
            totalMilliseconds += eventMilliseconds;
        } 
    })

    return totalMilliseconds;
  }
