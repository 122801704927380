import { percentageOfTimeSpentInDay, percentageOfTimeSpentInWakefulDay } from "../analytics-helpers/percentageOfTime";
import { calculateRank, minutesSpentInADay } from "../analytics-helpers/rank";

export interface BasicAnalyticViewProps {
    spentMilliseconds: number,
    totalMilliseconds: number
}

export const BasicAnalyticView = (props: BasicAnalyticViewProps) => {
    let { spentMilliseconds, totalMilliseconds } = props;

    let numberOfMinutes = spentMilliseconds / (1000 * 60);

    return (<>
        <b>Percentage of Awake Hours (17 hours): </b> {percentageOfTimeSpentInWakefulDay(spentMilliseconds, totalMilliseconds, 1000 * 60 * 60 * 7)}% <br/>
        <b>Percentage of Total Hours (24 hours): </b> {percentageOfTimeSpentInDay(spentMilliseconds, totalMilliseconds)}% <br/>
        <b>Minutes of 24 hours: </b> {minutesSpentInADay(spentMilliseconds, totalMilliseconds)} <br/>
        <b>Rank: </b> {calculateRank(spentMilliseconds, totalMilliseconds)}<br/>
        <b style={{color: "orange"}}>Total Minutes: </b> {numberOfMinutes} <br/>
    </>);
}