/* The percentage of time that is spent on an average 24 hour period - the time you sleep, on a given task. */
export const percentageOfTimeSpentInWakefulDay = (spentMilliseconds: number, totalMilliseconds: number, millisecondsSleptInDay: number) => {
    let MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
    let millisecondsAwakeInDay = MILLISECONDS_IN_A_DAY - millisecondsSleptInDay;

    let noOfDays = totalMilliseconds / MILLISECONDS_IN_A_DAY;
    let millisecondsSpentInDay = spentMilliseconds / noOfDays;

    return ((millisecondsSpentInDay / millisecondsAwakeInDay) * 100).toFixed(1); 
}

/* The percentage of time that is spent on an average 24 hour period */
export const percentageOfTimeSpentInDay = (spentMilliseconds: number, totalMilliseconds: number) => {
    return percentageOfTimeSpentInWakefulDay(spentMilliseconds, totalMilliseconds, 0)
}