/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { getEventsInPeriod } from './gcal-requests/events';
import { sumEventTimeInMilliseconds } from './gcal-helpers/events';
import { DayPicker } from './TimeRangePicker/DayPicker';
import { CustomRangePicker } from './TimeRangePicker/CustomRangePicker';
import { TimeRangePickerProps } from './TimeRangePicker/_props';
import { WeekPicker } from './TimeRangePicker/WeekPicker';
import { MonthPicker } from './TimeRangePicker/MonthPicker';

// Setting dayjs locale to UK, so the first day of the week is Monday
import "dayjs/locale/en-gb"
import { percentageOfTimeSpentInDay, percentageOfTimeSpentInWakefulDay } from './analytics-helpers/percentageOfTime';
import { calculateRank, minutesSpentInADay } from './analytics-helpers/rank';
import { BasicAnalyticView } from './AnalyticView/BasicAnalyticView';
import Info from './Info';
dayjs.locale("en-gb")



function App() {

  const [ user, setUser ] = useState<any>(dayjs('2022-04-17'));

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error),
    scope: "https://www.googleapis.com/auth/calendar",
  });

  const logout = () => {
    googleLogout();
    setUser(null);
  }

  const [calendars, setCalenders] = useState<any>(null);
  const [currentCalendar, setCurrentCalendar] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [numberOfMilliseconds, setNumberOfMilliseconds] = useState<number | null>(null);

  const [timeRangePicker, setTimeRangePicker] = useState<string>("Day");
  const timeRangePickerProps: TimeRangePickerProps = {
    setStartDateTime: setStartDate,
    setEndDateTime: setEndDate 
  }

  const dateToString = (date: Date) => {
    return date.getHours() + ":" + ( date.getMinutes() === 0 ? "00" : date.getMinutes() ) +  " " + date.toDateString();
  }

  // Fetching the list of calendars 
  // TODO: Stop this from being called all the time
  useEffect(
    () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/calendar/v3/users/me/calendarList`, {
              headers: {
                  Authorization: `Bearer ${user.access_token}`,
              }
          })
          .then((res) => {
              setCalenders(res);
          })
          .catch((err) => { console.log(err) });
      }
    },
    [ user ]
  );  

  /* Fetching the events in the given period, to then provide analytics. */
  useEffect(() => {
    if (currentCalendar && startDate && endDate) {
      console.log("SENT")
      getEventsInPeriod(currentCalendar, user.access_token, startDate, endDate)
        .then((events) => {setNumberOfMilliseconds(sumEventTimeInMilliseconds(events)); console.log("SET")});
    }
  }, [currentCalendar, startDate, endDate])

  return (
      <>
        <Info/>
        <hr/>
        {/* ---- TOP BAR ---- */}
        <Stack direction="row" sx={{padding: 1, borderBottom: 1}}>
          { !user && <Button variant='outlined' onClick={() => login()}>Sign In</Button> }
          { user && <Button variant='outlined' onClick={() => logout()}>Sign Out</Button> }
          { user && 
          <>
            <FormControl sx={{width: 200, marginLeft: 1.5, marginRight: 1.5}}>
              <InputLabel>Calendar</InputLabel>
              <Select
                label="Calendar"
                value={currentCalendar}
                onChange={(e) => setCurrentCalendar(e.target.value)}>
                { calendars && calendars.data.items.map((calendar: any) =>
                  <MenuItem key={calendar.id} value={calendar.id}>{calendar.summary}</MenuItem> 
                )} 
              </Select> 
            </FormControl>

            <FormControl sx={{width: 200}}>
              <Select
                value={timeRangePicker}
                onChange={(e) => setTimeRangePicker(e.target.value)}>
                  <MenuItem key={"Day"} value={"Day"}>Day</MenuItem> 
                  <MenuItem key={"Week"} value={"Week"}>Week</MenuItem> 
                  <MenuItem key={"Month"} value={"Month"}>Month</MenuItem> 
                  <MenuItem key={"Custom"} value={"Custom"}>Custom DateTime Range</MenuItem> 
              </Select>
            </FormControl>
          </>}
        </Stack>
        
        {/* ---- TIME RANGE PICKER ---- */}
        { user && 
        <Stack sx={{margin: 1}} direction="row" spacing={1.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
            { timeRangePicker === "Day" && <DayPicker {...timeRangePickerProps}/> }
            { timeRangePicker === "Week" && <WeekPicker {...timeRangePickerProps}/> }
            { timeRangePicker === "Month" && <MonthPicker {...timeRangePickerProps}/> }
            { timeRangePicker === "Custom" && <CustomRangePicker {...timeRangePickerProps}/> }
          </LocalizationProvider>
          <Box sx={{padding: 2, paddingLeft: 1}}> 
            <b>
              { startDate && dateToString(startDate) } 
              { (startDate || endDate) && " => "}
              { endDate && dateToString(endDate) }
            </b>
          </Box>
        </Stack>}

        <hr/>
        
        { startDate && endDate && numberOfMilliseconds &&
          <BasicAnalyticView {...{spentMilliseconds: numberOfMilliseconds, totalMilliseconds: endDate.getTime() - startDate.getTime()}}/>
        }
      </>
  );
}

export default App;
