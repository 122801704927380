import { Button, Stack, Tooltip, Typography } from "@mui/material";

const Info = () => {
    const googleCalendarPurpose = 
        <div><big><big>
            <b>Time Tracking</b><br/><br/>
            Title of event - Describes specifically what was done. <br/><br/>
            Calendar of event - The category of action carried out. <br/>
            <hr/>
            <b>Daily Task List</b><br/><br/>
            An all day event should be created named "Daily Task List". In the description create a list of tasks that 
            should be focused on within that day. 
        </big></big></div>;
    const githubKanbanPurpose = 
        <div><big><big>
            Tracking all the different projects you are working on and their associated tasks.
        </big></big></div>;
    const habitTrackerPurpose = 
        <div><big><big>
            Tracking your regular habits (they do not need to be notated as tasks on the kanban boards).
        </big></big></div>;

    return (
        <>
        <Typography sx={{ marginTop: 1, marginLeft: 1, marginRight: 1 }}><b>Everyday, plan tomorrow's tasks. </b> With planetary, everything else falls in line.</Typography>
        <Stack sx={{ margin: 1}} direction="row" spacing={1}>
            <Tooltip title={googleCalendarPurpose}>
                <a href="https://calendar.google.com">
                    <Button variant="contained">
                        <b>Time Tracking & Daily Focus Tasks</b> &nbsp; (Google Calendar)
                    </Button>
                </a>
            </Tooltip>
            <Tooltip title={githubKanbanPurpose}>
                <a href="https://github.com/users/abhinandshibu/projects/1/views/1">
                    <Button variant="contained">
                        <b>Task Tracking</b> &nbsp; (GitHub Kanban Board)
                    </Button>
                </a>
            </Tooltip>
            <Tooltip title={habitTrackerPurpose}>
                <a href="https://docs.google.com/spreadsheets/d/1K3hwWuM5CuXRNGh_M5aHWlxqvoG6AkKXCPOl6T5YKsY/edit#gid=0">
                    <Button variant="contained">
                        <b>Habit Tracking</b> &nbsp; (Google Sheets)
                    </Button>
                </a>
            </Tooltip>
        </Stack>
        </>
    );
}

export default Info;