import { DatePicker } from "@mui/x-date-pickers";
import { TimeRangePickerProps } from "./_props";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

export const DayPicker = (props: TimeRangePickerProps) => {
    let { setStartDateTime, setEndDateTime } = props;

    const [date, setDate] = useState<Dayjs | null>(dayjs().startOf('day'));

    useEffect(() => {
        if (date) {
            let MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
            let startDate = date.toDate();
            let endDate = new Date(startDate.getTime() + MILLISECONDS_IN_A_DAY);
            setStartDateTime(startDate);
            setEndDateTime(endDate);
        }
    }, [date])
    
    return (
    <>
        <DatePicker
            label="Day"
            format="DD-MM-YYYY"
            value={date} 
            onChange={(date) => { setDate(date) }}
            />
    </>);
}