import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { TimeRangePickerProps } from "./_props";
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";

export const CustomRangePicker = (props: TimeRangePickerProps) => {
    let { setStartDateTime, setEndDateTime } = props;

    const [start, setStart] = useState<Date | null>(null);
    const [end, setEnd] = useState<Date | null>(null);

    // useEffect(() => {
    //     if (date) {
    //         let MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
    //         let startDate = new Date(date);
    //         let endDate = new Date(startDate.getTime() + MILLISECONDS_IN_A_DAY);
    //         setStartDateTime(startDate);
    //         setEndDateTime(endDate);
    //     }
    // }, [date])

    useEffect(() => {
        if (start) {
            setStartDateTime(new Date(start));
        }
    }, [start])
    
    useEffect(() => {
        if (end) {
            setEndDateTime(new Date(end));
        }
    }, [end])

    return (
    <Stack direction="row" spacing={1.5}>
        <DateTimePicker label="Start Time" value={start} onChange={(date) => { setStart(date) }}/>
        <DateTimePicker label="End Time" value={end} onChange={(date) => { setEnd(date) }}/>
    </Stack>);
}