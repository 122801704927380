import axios from "axios";

export const getEventsInPeriod = async (calendar: string, accessToken: string, startDate: Date, endDate: Date) => {
    let res = await axios
        .get(`https://www.googleapis.com/calendar/v3/calendars/${calendar}/events`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                timeMin: startDate,
                timeMax: endDate,
                maxResults: 2500 // TODO: In future we should make use of caching so we are not limited to 2500 results
            }
        })
    
    return res.data.items;
}

